@import '../../../../styles/darktheme/utilities/variables';

.schdule-status-content {
  padding: 46px 24px 20px 24px;
}

.max-height {
  max-height: 500px;
  overflow-x: scroll;
}

select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-300, #5d6675);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

select:focus {
  outline: none;
}

.select-name {
  margin-left: 1rem;
}

.schdule-status-wrapper {
  display: flex;
  align-items: center;
}

.text-name {
  color: var(--Primary-Indigo-400, #60bfda);
  font-size: 1.6rem;
  font-weight: 500;
}

.schdule-status-frame {
  display: flex;
  align-items: center;
}

.inner-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  margin-left: auto;
}

/*well control scss*/
.navigation-image-container {
  display: flex;
  gap: 14px;
}

.border-bottom {
  border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
}

.dropdown-btn {
  margin-left: 0;
}

.flex-btn {
  display: flex;
  align-items: center;
  gap: 12px;
}



.well-option {
  z-index: 1000;

  .disabled {
    color: #4A5463 !important;
    cursor: context-menu;
  }

  & button {
    padding: 12px;
    position: relative;
    font-size: 14px !important;
  }

  .well-status {
    position: absolute;
    min-width: 64px;
    width: auto;
    height: 22px;
    top: 10px;
    right: 15px;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  & .running {
    color: #32D583;
    border: 1px solid #32D583
  }

  & .idle {
    color: #F97066;
    border: 1px solid #F97066
  }

  & .blank {
    color: #FDB022;
    border: 1px solid #FDB022
  }

  .operating-mode {
    min-width: 37px;
    width: auto;
    height: 22px;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 15px;
  }

  & .exist {
    border: 1px solid #60BFDA;
    color: #60BFDA;
  }


}

.ico {
  display: block;
  width: 21px;
  height: 21px;

  &.plus {
    background: url('../../../../images/plus.png') no-repeat 0 0;
  }

  &.tool {
    background: url('../../../../images/tool-02.png') no-repeat 0 0;
  }

  &.chevrondown {
    background: url('../../../../images/chevron-down.png') no-repeat 0 0;
  }

  &.GearIcon {
    width: 24px;
    height: 24px;
    background: url('../../../../images/gearIcon.png') no-repeat 0 0;
  }
}

/* schdule-speed-change-header.scss */

.custom-dropdown {
  position: relative;
  cursor: pointer;
}

.custom-dropdown .dropdown-list{
  background-color: #001023;
}

.selected-option {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;

  span {
    font-size: 0.8rem;
    padding: 0 0 0 0.5rem;
  }
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;

  li {
    padding: 8px;
    color: $black;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.open .dropdown-list {
  display: block;
}

.pipe {
  color: #fff;
}

// asset control confirmation window css
.asset-confirmation-modal {
  height: 100vh;

  .modal {
    width: 384px !important;
    // height: 255.31px;

    .content-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 175px;
      gap: 10px;

      .heading {
        font-family: Mulish;
        font-size: 19px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #F7F9F9;
      }

      .description {
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        color: #B8C5CC;
      }
    }



    .button-div {
      border-top: 1px solid #4a5463;
      height: 80px;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      .btn {
        padding: 10px 16px 10px 16px;
        border-radius: 8px;
        width: 162px;
        height: 40px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        //styleName: Paragraph 1 [P1]/Bold;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }

      .cancel {
        background: #131F2E;
        border: 1px solid #4A5463
      }

      .Start {
        background: #00789E;
        border: 1px solid #005F7C
      }

      .Stop {
        background: #D92D20;
        border: 1px solid #D92D20
      }

      .ClearAlarms {
        background: #12B76A;
        border: 1px solid #039855
      }
    }
  }

}

.asset-control-block {
  margin-top: 0px !important;
}

.asset-control-modal-heading {
  border-bottom: none !important;
  background: none !important;
}

.scan-loader-modal {
  height: 100vh;

  .modal {
    height: 381px !important;
    width: 384px !important;

    .notify-card {
      height: 381px !important;
      width: 384px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }

    .heading-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2px;

      .heading-1 {
        width: 336px;
        height: 34px;
        font-family: Mulish;
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: -0.04em;
        text-align: center;
        color: #F7F9F9;
      }

      .asset-name {
        width: 384px;
        height: 27px;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #F7F9F9;
      }

    }

    .loading-content {
      width: 70px;
      height: 84px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      @keyframes icon {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .icon {
        animation: spin 3s linear infinite;
      }

      .lebel {
        width: 70px;
        height: 20px;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #323F46;
      }
    }

  }
}

.ping-processing-modal {
  height: 100vh;

  .modal {
    width: 384px;
    // height: 589px;

    .content-div {
      width: 384px;
      // min-height: 509px;
      max-height: calc(80vh - 80px) !important;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      padding: 15px 0px;
      overflow-y: auto;
      overflow-x: hidden;

      .header-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .heading {
          font-family: Mulish;
          font-size: 28px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #F7F9F9;
          margin-bottom: 10px;

        }

        .asset-name {
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #F7F9F9;
          opacity: 0.8;
        }

        .connection-description {
          //styleName: Paragraph 1 [P1]/Regular;
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #F7F9F9;
          opacity: 0.8;
        }
      }

      .main-icon {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .button-div {
      width: 384px;
      height: 80px;
      padding: 20px 24px 20px 24px;
      border-top: 1px solid #4A5463;
      display: flex;
      justify-content: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 336px;
        height: 40.65px;
        background: #131F2E;
        border: 1px solid #4A5463;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: #F7F9F9;
      }
    }
  }
}

.ping-data-modal {
  height: 100vh;

  .modal {
    width: 384px;
    // height: 589px;

    .content-div {
      width: 384px;
      max-height: calc(80vh - 80px);
      overflow-y: auto;
      overflow-x: hidden;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;
      padding: 20px 18px 40px 18px;
      // gap: 20px;

      .header-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .heading {
          font-family: Mulish;
          font-size: 28px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #F7F9F9;
          margin-bottom: 10px;

        }

        .asset-name {
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #F7F9F9;
          opacity: 0.8;

        }

        .connection-description {
          //styleName: Paragraph 1 [P1]/Regular;
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #F7F9F9;
          opacity: 0.8;

        }
      }

      .connection-div {
        width: 348px;
        height: 183px;
        opacity: 0.8;
        margin-top: 50px;

        .connection {
          width: 348px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: Mulish;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #F7F9F9;
          padding: 0px 20px;

          .value {
            font-weight: 400;
          }
        }
      }

      .summary-div {
        width: 348px;
        // height: 305px;
        border-radius: 8px;
        border: 1px solid #4A5463;

        .data,
        .summary-heading {
          width: 348px;
          height: 48px;
          font-family: Mulish;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #F7F9F9;
        }

        .summary-heading {
          font-family: Mulish;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          justify-content: flex-start;
          padding-left: 20px;
          padding-top: 15px;
        }

        .data {
          border-top: 1px solid #4A5463;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 20px;
          opacity: 0.8;

          .value {
            font-weight: 400;
          }
        }
      }
    }

    .button-div {
      width: 384px;
      height: 80px;
      padding: 20px 24px 20px 24px;
      border-top: 1px solid #4A5463;
      display: flex;
      justify-content: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 336px;
        height: 40.65px;
        background: #131F2E;
        border: 1px solid #4A5463;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: #F7F9F9;
      }
    }
  }
}

.theme-dark {
  & .dropdown-options {
    & button.asset-control-heading {
      border-bottom: 1px solid var(--dark-mode-400, #4a5463) !important;
      border-radius: 0;
    }
  }
}

.dashboard-first-block {
  .badges-section {
    display: inline-flex;
    gap: 15px;
    padding-left: 20px;

    span {
      display: inline-flex;
    }

    .well-status {
      min-width: 66px;
      width: auto;
      height: 24px;
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
      font-family: Mulish;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
    }

    & .running {
      color: #32D583;
      border: 1px solid #32D583
    }

    & .idle {
      color: #F97066;
      border: 1px solid #F97066
    }

    & .blank {
      color: #FDB022;
      border: 1px solid #FDB022
    }

    .operating-mode {
      min-width: 37px;
      width: auto;
      height: 22px;
      padding: 2px 8px 2px 8px;
      border-radius: 4px;
      border: 1px 0px 0px 0px;
      font-family: Mulish;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
    }

    & .exist {
      border: 1px solid #60BFDA;
      color: #60BFDA;
    }

    .sm-highlt {
      display: flex;
      gap: 5px;
      align-items: center;
      border-radius: 0.25rem;
      padding: 2px 8px 2px 8px;
      font-size: 14px;

      img {
        width: 16px;
        height: 16px;
      }

      &.Pending {
        border: 1px solid #60BFDA;
        color: #60BFDA;
      }

      &.InProgress {
        border: 1px solid #F68D2E;
        color: #F68D2E;
      }

      &.Completed {
        border: 1px solid #32D583;
        color: #32D583;
      }

      &.Failed,
      &.Cancelled,
      &.Shutdown {
        border: 1px solid #F97066;
        color: #F97066;
      }
    }
  }

  @media (max-width: 1450px) {
    .badges-section {
      gap: 5px;
      padding-left: 8px;

      .well-status {
        min-width: 55px;
        font-size: 11px;
      }

      .operating-mode {
        font-size: 11px;
      }

      .sm-highlt {
        font-size: 11px;
      }
    }
  }
}