  /* For WebKit Browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }

  ::-webkit-scrollbar:horizontal {
    width: 8px; /* width of the scrollbar */
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #808080; /* color of the scrollbar thumb */
    border-radius: 6px; /* roundness of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    // background-color: #f1f1f1; /* color of the scrollbar track */
    background-color: transparent; /* color of the scrollbar track */
  }

  /* Optional: Handle on hover state */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .dashboard-first-block {
    position: relative;
  }
  .overlay-section {
    position: absolute;
    width: 50%;
    height: 396px;
    right: 5rem;
    top: 16rem;
    z-index: 1;

    &.first {
      // right: 15rem;
      .card {
        padding-bottom: 1.2em;
      }
      .table-header-item {
        background: $dark-primary;
      }
    }
    &.sec {
      right: 2rem;
      .card {
        padding-bottom: 1.2em;
      }
      .table-header-item {
        background: $dark-primary;
      }
    }
    &.third {
      position: absolute;
      top: 16.7rem;
      .card {
        padding-bottom: 1.2em;
      }
      .table-header-item {
        background: $dark-primary;
      }
    }
    .card {
      max-width: 70rem;
      max-height: 30rem;
      overflow-y: scroll;
      background: #fff !important;
    }
  }
  .date-input {
    margin: 0 1rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    border-color: #d0d8dd;
  }

  .react-grid-layout {
    width: 100%;
    .react-grid-item {
      z-index: 9;

      .expand-btn {
        z-index: 999999;
        width: 20px;
        height: 20px;
        margin-right: 0.75rem;
      }
    }
  }

  .ReactModalPortal {
    .ReactModal__Overlay--after-open {
      z-index: 9999;
      .ReactModal__Content {
        padding: 0!important;
        border-radius: 8px !important;
        border: 1px solid var(--Dark-mode-400, #4A5463) !important;
        background: var(--Darkmode-700, #131F2E) !important;
        width: calc(100vw - 50rem);
        overflow: auto !important;
        max-height: 580px;
        /* width: calc(100% - 110px) !important;
        height: auto;
        margin-left: 34px;
        min-height: 85%;
        max-height: 650px; */
        display: flex;
        flex-direction: column;
        & .chart-wrapper {
          @media (min-width: 1500px){
              height: 280px !important;
          }
          @media (min-width: 1850px){
            height: 350px !important;
          }
          @media (min-width: 2200px){
            height: 360px !important;
          }
        }
        &.wellProTrends, &.wellPerTrends, &.fullModelView {
          width: calc(100% - 110px) !important;
          height: auto;
          margin-left: 34px;
          min-height: 85%;
          max-height: 650px;
          & .modal-toaster {
            position: absolute;
            z-index: 10000;
            width: 500px;
            right: 1rem;
            top: 1rem;
            background-color: var(--Dark-mode-800, $dark-primary);;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            border-radius: 8px;
            & .normal-text {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              color: $error-dark-shade5;
              text-align: center;
              display: flex;
              flex-wrap: wrap;
              padding-left: 0.5rem;
            }
          }
          & .modal-content {
            flex-grow: 1;
            #wellProductionTrendsChartModaldiv, #wellPerformanceTrend-container, .wellPreGraph {
              height: 320px;
              @media (max-width: 1450px){
                height: 300px !important;
              }
              @media (min-width: 1500px){
                  height: 400px !important;
              }
              @media (min-width: 1850px){
                height: 480px !important;
              }
              @media (min-width: 2200px){
                height: 700px !important;
              }
              @media (min-width: 3000px){
                height: 900px !important;
              }
              @media (min-width: 3500px){
                height: 1100px !important;
              }
              @media (min-width: 4000px){
                height: 1500px !important;
              }
            }  
          }
        }
        &.equipmentModal {
          width: calc(100% - 400px) !important;
          height: auto;
          margin-left: 34px;
          min-height: 85%;
          max-height: 650px;
        }
      }
      @media #{$largeDesktop}, #{$xxlargeDesktop}, #{$mediumtolargeDesktop} {
        .ReactModal__Content {
          width: 650px;
          //height: auto;
          max-height: 580px;
          &.wellProTends {
            width: calc(100% - 110px) !important;
            height: auto;
            margin-left: 34px;
            min-height: 85%;
            max-height: 650px;
          }
        }
      }

      .modal-header {
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        padding: 1.25rem;
        // margin-bottom: 0.5rem;
        h3 {
          color: #fff;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
      .modal-content {
        padding: 1rem;
        .chart-wrap {
          background: #131f2e;
          text-align: center;
          padding: 0.25rem;
          // overflow-y: hidden;
          // overflow-x: auto;
          // max-height: calc(100vh - 300px);
          // height: auto;
          & .total-count {
            p {
              margin-bottom: 0;
            }
            h2 {
              color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
              font-family: Mulish;
              font-size: 60px;
              font-style: normal;
              font-weight: 700;
              line-height: 72px;
            }
          }
          &.ticket-modal {
            .table-header-cell, .table-header-item, td {
              background: transparent;
              color: #fff;
            }
          }
        }
        p {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        }
      }
    }
  }
  .dashboard-count.ui {
  & > div {
      border-right: 1px solid #5D6675;
      padding: 0 4rem 0 1.5rem;
      &:last-child {
        padding: 0 0.5rem 0 0;
        border-right-width: 0;
      }
      .link {
        cursor: pointer;
      padding: 0.5rem 1rem;
        border-radius: 0.5rem;
      &:hover {
        background-color: #000;
      }
    }
  }
}
  .table-container.w-full {
    margin: 0;
    border: 0;
    .document-table {
      width: 100%;
    th, td {
        //text-align: left;
          //padding: 1rem;
        border-bottom: 1px solid #d0d8dd;
      }
    }
  }
  .right-modal {
    background: #f1f4f5;
  }
  .custom-text-input {
    padding: 0.75rem 0.5rem;
    width: 100%;
    border-radius: 8px;
  }
  .relative.block {
    .custom-text-input {
      padding-left: 2rem;
    }
  }
  .profile-block {
    text-align: left;

    .form-col {
      padding: 0 0.5rem;
      label {
        font-size: 1.2rem;
        font-weight: normal;
        padding-bottom: 0.5rem;
      }
      select {
        option {
          padding: 2rem;
        }
      }
    }
  }
  .delete-modal-page {
    &.file-error {
      .modal {
        width: 360px;
        .notify-card {
          .title {
            color: #000;
          }
        border-width: 1px ;
          border-radius: 0.5rem;
          p {
            color: #000;
          }
          .header-delete {
            padding-bottom: 2rem;
          }
          .btn-wrap {
            padding: 0 1rem 1rem 1rem;
            .btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .category-checkboxes {
    position: absolute;
    width: 100%;
    border: 1px solid #d0d8dd;
    background: #fff;
    &>div {
      padding: 1rem 1rem 0 1rem;
      &:last-child {
        padding-bottom: 1rem;
      }
      label {
        padding-left: 0.5rem;
      }
    }
  }
  .ico-btn {
    color: inherit;
    &.dwnld {
      border-right: 1px solid #A0B1BB;
      margin: 0.3rem 1rem 0 0;
      padding-right: 1rem;
      height: 2.5rem;
    }
  }
  .table-container {
  thead.document-head, tfoot.document-foot {
      position: sticky;
      top: 0;
      right: 0;
      background: #fff;
    }

    tfoot.document-foot {
      bottom: 0;
      left: 0;
      .pagination-link {
        color: inherit;
        margin-right: 0.5rem;
        padding: 0.2rem 0.5rem;
        &.active {
          color: #fff;
        }
      }
    }
  }
  .column-drawer {
    width: 100%;
    padding: 1rem;
    button {
      color: inherit;
    }

    .column-list {
    > div {
        padding: 0.5rem 2.5rem 0.5rem 0;
        display: inline-block;
      }
      label input {
        margin-right: 1rem;
      }
    }
  }
  .btn.view-all {
    color: #5bb5d0;
    padding: 0;
    font-size: 1rem;
  }
  .ui-modal-grid {
    &.table-container-dashboard {
      max-height: calc(100vh - 500px);
      padding: 0 1rem;
      margin-top: 1rem;
    }
    overflow: auto;
    max-height: calc(100vh - 380px);
    margin-bottom: 25px;

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
    }

    th {
      background: #131f2e;
    }
  }
  .ui-modal-pagination {
    button {
      margin: 0 1rem;
    }
  }

  .ReactModalPortal {
    .ui-modal-grid {
      thead th {
        font-weight: bold;
        border-bottom: 1px solid #4a5463;
      }
      tr {
        border-bottom: 1px solid #4a5463;
      }
    }
  }

  .nodata {
    height: 260px;
    & .no-data {
      margin-top: 0;
    }
  }

  .dashboard-graphs-wrapper {
    .graph-card {
      display: flex;
      flex-direction: column;
      & .nodata {
          height: 100%;
          max-height: 100%;
          overflow: hidden;
          & .chart-wrapper {
            height: 100%;
            max-height: 100%;
          }
        }
      & .wellProductionTrendsChartSection, & .wellPerformanceChartSection {
        height: 100%;
        max-height: 100%;
        width: 100%;
        flex-grow: 1;   
        display: flex;
        flex-direction: column;
        overflow: hidden;
        & .well-production-loader, & .well-performance-loader {
          margin-top: 0;
          flex-grow: 1;
        }     
        & .no-data {
            margin-top: 0;
        }
        & #wellProductionTrendsChartdiv {
          height: 100%;;
          max-height: 100%;
        }
      }
    }
    
  }

  .loader-margin {
    & .loader-container {
      margin-top: 100px;
    }
  }
