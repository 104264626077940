@import '../../../../styles/darktheme/utilities/variables';

.asset-list {
    &__card {
        border-radius: 8px 8px 0 0;
        border: 1px solid var(--Dark-mode-400, #4A5463);
        background: var(--Darkmode-700, #131F2E);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        // max-height: calc(100vh - 380px);
        overflow: auto;
        min-height: 250px;
        position: relative;
    }
    .no-data{
        margin-top: -10px;
    }

    &__table {
        width: max-content;
        // max-width: 1200px;
        border-collapse: collapse;
        &.table-opacity {
            background: rgba(0, 0, 0, 0.5);
            filter: blur(4px);
        }
    }

    &__header {
        position: sticky;
        top: 0;
        z-index: 8;
        width: max-content;
        & .__cell {
            font-size: 12px;
        }
    }

    &__main-content {
        max-height: calc(100vh - 380px);
    }

    &__row {
        display: flex;
    }

    &__cell-two {
        width:286px;
        min-height: 44px;
        padding: 16px 12px;
        background: var(--Darkmode-700, #131F2E);
        color: var(--Primary-Indigo-400, var(--Primary-Indigo400, #60BFDA));
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        border-right: 1px solid var(--Dark-mode-400, #4A5463);
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        display: flex;
        align-items: center;
        cursor: pointer;
        word-break: break-word;
        position: sticky;
        left: 0;
        z-index: 5;
        &:hover, &:focus, &:active {
            text-decoration: underline;
        }
    }

    &__cell-one {
        width: 125px;
        min-height: 44px;
        padding: 16px 12px;
        background: var(--Darkmode-700, #131F2E);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        display: flex;
        align-items: center;
        text-wrap: nowrap;
        cursor: pointer;
        @media (min-width:2500px) and (max-width:3100px) {
            text-wrap: wrap;
        }
        &.head {
            border-right: 1px solid var(--Dark-mode-400, #4A5463);
            width: 286px;
            position: sticky;
            left: 0;
            z-index: 8;
        }
        &.lat {
            width: 186px;
            @media (min-width:2500px) and (max-width:3100px) {
                width: 200px;
            }
        }
        &.powerCon{
            width: 150px;
        }
        .sort-img-show-hover{
            display: none;
        }
        &:hover .sort-img-show-hover{
            display: block;
        }
        &.uptimehead {
            width: 210px;
            /* @media (min-width:2500px) and (max-width:3100px) {
                width: 10%;
            } */
           /*  @media (min-width:2700px) and (max-width:3100px) {
                width: 240px;
            }
            @media (min-width:2800px) and (max-width:3100px) {
                width: 235px;
            }
            @media (min-width:2900px) and (max-width:3100px) {
                width: 225px;
            } */
        }
        
    }

    &__cell {
        width: 125px;
        min-height: 44px;
        padding: 16px 12px;
        background: var(--Darkmode-700, #131F2E);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        display: flex;
        align-items: center;
        &.lat {
            width: 186px;
            min-width: 186px;
            text-wrap: nowrap;
        }
        &.powerCon{
            width: 150px;
        }
        &.uptime-chart-body {
            width: 210px;
            position: relative;
            .asset-table-uptime-chart {
                position: relative;
                & .expand-btn {
                    cursor: pointer;
                    z-index: 0;
                    position: absolute;
                    right: -32px;
                    top: 50%;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                }
                &:hover {
                    & .expand-btn {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media #{$miniDesktoptwo} {
        &__table {
            width: max-content;
        }
        &__cell-two {
            width: 100px;
            height: auto;
            min-height: 60px;
            // max-height: 130px;
        }
        &__cell-one {
            width: 80px;
            height: auto;
            min-height: 60px;
            // max-height: 130px;
            &.head {
                width: 100px;
            }
        }
        &__cell {
            width: 80px;
            word-break: break-word;
            height: auto;
            min-height: 60px;
        }
    }
    @media #{$xxlargeDesktop} {
        &__table {
            width: max-content;
        }
        &__cell-two {
            width: 240px;
        }
        &__cell-one {
            width: 120px;
            &.head {
                width: 240px;
            }
        }
        &__cell {
            width: 120px;
            word-break: break-word;
        }
    }
    @media #{$mediumtolargeDesktop} {
        &__table {
            width: 100%;
        }
        &__cell-one {
            width: 5.5%;
            &.head {
                width: 10%;
            }
        }
        &__cell {
            width: 5.5%;
        }

        &__cell-two {
            width: 10%;
        }
        &__header {
            width: 100%;        
        }
    }
    @media (min-width:2500px) and (max-width:3100px) {
        &__cell-one {
            &.head {
                width: 10%;
            }
        }
    }
    @media (min-width:2800px) {
        &__cell-one {
            &.head {
                width: 10%;
            }
        }
    }
    &__loader-margin {
        & .loader-container {
            position: absolute;
            top: 120px;
        }
    }
}


.asset-list__card .loader-container{
    position: sticky !important;
    width: 100%;
    height:  100%;
    top: 50%;
    z-index: 99999;
}

.sort-icon {
    padding-left: 4px;
}

.sort-icon img{
    max-width: unset;
}

.loader-opacity {
    .loader-container {
        position: absolute;
        z-index: 9999;
        top: 150px;
    }
}

.performanceSnapshot {
    .tab-content {
        .assetList-map {
            margin-top: 12px;
        }
        .assetList-alarms {
            margin-top: 0;
            & .profile-card {
                margin-top: 12px;
            }
        }
    }
}
