@import '/src/styles//darktheme/utilities/variables';

.Tickets-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .modal-content {
    border-radius: 8px;
    border-bottom: 1px solid #4a5463;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    background: var(--Dark-mode-800, #001023);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    position: relative;
    min-height: 300px;
    height: 83%; //height: (888 / 1080) * 100 = 82.22%
    width: 60%; //width: (1140 / 1920) * 100 = 59.38% 

    .sujectField-area {
      label {
        display: flex;
        width: 100%;
        text-align: left;
        margin: 0 0 5px;
      }

      .sujectField {
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #F7F9F9;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #F7F9F9;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #F7F9F9;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: #F7F9F9;
        }
      }
    }

    .ticket-comment-attachments {
      & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        & .ticket-comment-attachment-link {
          button {
            text-align: left;
            margin: 4px 0;
            cursor: pointer;
            color: #60BFDA;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            white-space: normal;
            word-wrap: break-word;
          }
        }
      }
    }

    .modal-header {
      height: 70px;
      border-radius: 8px;
      align-items: center;
      display: flex;
      background: var(--Dark-mode-800, #001023);
      justify-content: space-between;
      padding: 25px 80px 8.5px 28px;


      .ticket-status {
        // height: 44px;
        // border-radius: 8px;
        // padding: 10px 12px;
        // gap: 8px;
        // background: #001023;
        // border: 1px solid #4A5463;
        // box-shadow: 0px 1px 2px 0px #1018280D;
        width: 150px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #F7F9F9;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .modal-title {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: -1.12px;
        color: var(--Primary-Indigo-25, var(--Primary-Indigo25, #f4fbfc));
        margin: 0;
      }

      .close-button {
        position: absolute;
        width: 28px;
        padding: 4px;
        right: 27px;
        cursor: pointer;
        font-size: 20px;
        color: #333;
      }

      .dropdowns-container {
        display: flex;
        margin-left: 16px;
        border-radius: 8px;
        border: 1px solid var(--Darkmode-400, #4a5463);
        position: relative;
        color: #ccc;
        display: inline-block;

        .ticket-actions {
          display: flex;
          width: 135px;
          padding: 10px 12px;
          align-items: center;
          gap: 8px;
          display: flex;
          align-items: center;
          border-radius: 8px;

          .ticket-option {
            width: 135px;
          }
        }
      }

      .modal-left {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .header-right-actions {
        display: flex;
        align-items: center;
        gap: 8px;

        .ticket-assignee {
          width: 31px;
          height: 30px;
          border-radius: 30px;
          background: #437EF7;
          border: 1px solid #7FBDC9;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--Neutral-Grey25, #F7F9F9);
        }

        .ticket-updated-at {
          height: 28px;
          border-radius: 4px;
          padding: 4px 12px;
          border: 1px solid #B8C5CC;
          font-family: Mulish;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0%;
          text-align: center;
          color: #F7F9F9;
        }


      }
    }

    .modal-content-body {
      display: flex;
      height: 85%;
      overflow-y: auto;

      .selected-tickets-field {
        padding: 22px 20px 22px 22px;
        display: flex;
        flex-direction: column;
        width: 45%;

        .selected-tickets-all-div {
          .selected-tickets-div {
            min-height: 48px;
            margin-bottom: 16px;
            border-radius: 8px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            border: 1px solid var(--Dark-mode-400, #4A5463);
            background: var(--Dark-mode-700, #131F2E);

            &.multipaltab {
              padding: 16px;
            }

            .selected-filter-div h4 {
              color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
            }


          }
        }
      }

      .tickets-textarea-box {
        display: flex;
        flex-direction: column;
        width: 65%;
        padding: 15px 24px 29px 3px;
      }

      .tickets-textarea-width {
        overflow-y: auto;
        height: 49%;
        position: fixed;
        width: 35%;
      }

      .tickets-text-area {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 115px;
        width: 34%;
        gap: 10px;
        padding: 0px 0px 10px 12px;
        color: #fff;
        margin-top: auto;

      }

      .textarea-message {
        width: 100%;
        height: 100px;
        resize: none;
        padding: 10px;
        background: #131f2e;
        border: 1px solid #4a5463;
        color: #fff;
        border-radius: 5px;
        outline: none;
        margin-top: 8px;
      }

      .attachment-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }

      .attachment-container .loader-container .loader {
        width: 27px;
        height: 27px;
      }

      .footer-modal {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        flex-grow: 1;
      }

      .click-btn {
        padding: 8px 14px;
        border-radius: 8px;
        cursor: pointer;
      }

    }
  }
}

//60%
@media (max-width: 4098px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 49%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 115px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

//75% 
@media (min-width: 1728px) and (max-width: 1919px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 45%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 85px;
        }
      }
    }
  }
}

//80%
@media (min-width: 1518px) and (max-width: 1727px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 45%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 75px;
        }
      }
    }
  }
}

//90%
@media (max-width: 1707px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 41%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 70px;
        }
      }
    }
  }
}

//100%
@media (max-width: 1517px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 37%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 65px;
        }
      }
    }
  }
}

//110%
@media (min-width: 1152px) and (max-width: 1299px) {
  .modal-content {
    .modal-content-body {
      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 30%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 58px;
        }
      }
    }
  }
}

//125%
@media (max-width: 1149px) {
  .modal-content {
    .modal-content-body {
      height: 80% !important;

      .tickets-textarea-box {
        .tickets-textarea-width {
          height: 15%;
          width: 33%;
        }

        .tickets-text-area {
          position: fixed;
          bottom: 60px;
          width: 33%;
        }
      }
    }
  }
}